import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { loginStore } from "./LoginStore";
import InputPassword from "../../../common/component/InputPassword";
import { Link, useSearchParams } from "react-router-dom";
import SelectAccount from "./SelectAccount";
import { useTranslation } from "react-i18next";

const LoginPage = observer(() => {
  let searchParams: URLSearchParams;
  [searchParams] = useSearchParams();
  let url = window.location.href;

  useEffect(() => {
    if (localStorage.getItem("listAccount")) {
      loginStore.showForm = false;
      let listAcc: any = localStorage.getItem("listAccount");
      loginStore.listAccount = JSON.parse(listAcc);
    }
    if (searchParams.get("form")) {
      loginStore.showForm = true;
      loginStore.username =
        searchParams.get("username") === "null"
          ? ""
          : (searchParams.get("username") as any);
    }
    if(localStorage.getItem('account_verify')) {
      loginStore.username =  String(localStorage.getItem('account_verify'))
    }
    loginStore.isCms = searchParams.get("cms");
    loginStore.service = searchParams.get("service");
    loginStore.redirectTo = searchParams.get("redirectTo");
  }, [searchParams, url]);
  const { t } = useTranslation(["translation"], {
    keyPrefix: "login",
  });
  return (
    <div className="login_main">
      {loginStore.listAccount &&
        loginStore.listAccount.length > 0 &&
        loginStore.showForm && (
          <div className="button_back_list_account_container">
            <button
              onClick={() => (loginStore.showForm = false)}
              className="button_back_list_account"
            >
              <img
                style={{ marginRight: "6px" }}
                src="/assets/images/arrow-big-left.svg"
                alt=""
              />
              <span style={{ color: "#000" }}>{t("accountList")}</span>
            </button>
          </div>
        )}
      <div className="login_main_container">
        <div className="form_login bg-white">
          <div className="logo_login d-flex justify-content-center">
            <img src="/assets/images/logo.svg" alt="" />
          </div>
          {loginStore.listAccount &&
          loginStore.listAccount.length > 0 &&
          !loginStore.showForm ? (
            <h2 className="text-center mb-3">{t("chooseAccount")}</h2>
          ) : (
            <>
              <h2 className="text-center d-none d-lg-block">{t("title")}</h2>
              <p className="text-center d-none d-lg-block">
                {t("subTitle")}
              </p>
            </>
          )}
          {loginStore.listAccount &&
          loginStore.listAccount.length > 0 &&
          !loginStore.showForm ? (
            <SelectAccount />
          ) : (
            <form autoComplete="off">
              <div className="form-group">
                <label>
                  {t("phoneNumber")} <span className="text-danger">*</span>
                </label>
                <div className="input-group">
                  <input
                    type="text"
                    value={loginStore.username}
                    onChange={(e: any) => {
                      loginStore.setUserNameValue(e);
                    }}
                    onKeyDown={async (e: any) => {
                      if (e.key === "Enter") {
                        await loginStore.login()
                      }
                    }}

                    className={`form-control form-control-lg ${
                      loginStore.formError.name ? "border_error" : ""
                    }`}
                    placeholder={t("enterNumberPhone")}
                  />
                </div>
                <p className="error">
                  {loginStore.formError && loginStore.formError.name}
                </p>

              </div>
              <div className="form-group">
                <label>
                  {t("password")}
                  <span className="text-danger">*</span>
                </label>
                <InputPassword isShow={loginStore.showPass}
                  value={loginStore.password}
                  onChange={loginStore.setPassValue}
                  isError={loginStore.formError.pass}
                  onKeyDown={loginStore.enterLogin}
                  placeholder={t("enterPassword")}
                />
              </div>
              <div className="my-2 mt-2 d-flex justify-content-between align-items-center">
                <div className="d-flex justify-content-start align-items-center">
                  <input
                      type="checkbox"
                      id="checkbox"
                      className={`cursor-pointer 
                        w-4 h-4 border-3 rounded-sm`}
                      style={{ marginRight: "6px", cursor: "pointer" }}
                      checked={loginStore.showPass}
                      onChange={(e) => {
                        loginStore.showPass = !loginStore.showPass;
                      }}
                  />
                  <label
                      htmlFor="checkbox"
                      style={{ cursor: "pointer" }}
                      className="form-check-label mb-0 text-muted"
                  >
                    Hiển thị mật khẩu
                  </label>
                </div>
                {/*<div>*/}
                {/*  <Link to={`/verify-otp${*/}
                {/*      searchParams.get("redirectTo")*/}
                {/*          ? `?redirectTo=${searchParams.get("redirectTo")}`*/}
                {/*          : ""*/}
                {/*  }${*/}
                {/*      searchParams.get("service")*/}
                {/*          ? `&service=${searchParams.get("service")}`*/}
                {/*          : ""*/}
                {/*  }${*/}
                {/*      searchParams.get("name")*/}
                {/*          ? `&service=${searchParams.get("name")}`*/}
                {/*          : ""*/}
                {/*  }${*/}
                {/*      searchParams.get("lng")*/}
                {/*          ? `?lng=${searchParams.get("lng")}`*/}
                {/*          : ""*/}
                {/*  }`} onClick={() => {*/}
                {/*    loginStore.account_verify = ''*/}
                {/*    localStorage.setItem('type_verify', 'FORGOT')*/}
                {/*  }} className='btn_forgot__password'>*/}
                {/*    Quên mật khẩu*/}
                {/*  </Link>*/}
                {/*</div>*/}
              </div>

              <div className="footer_action">
                <Link
                  to={`/register${
                    searchParams.get("redirectTo")
                      ? `?redirectTo=${searchParams.get("redirectTo")}`
                      : ""
                  }${
                    searchParams.get("service")
                      ? `&service=${searchParams.get("service")}`
                      : ""
                  }${
                    searchParams.get("name")
                      ? `&service=${searchParams.get("name")}`
                      : ""
                  }${
                    searchParams.get("lng")
                      ? ((searchParams.get("redirectTo") || searchParams.get("service")) ? `&lng=${searchParams.get("lng")}` : `?lng=${searchParams.get("lng")}`) : ""
                  }`}
                  className="btn bt-register"
                >
                  {t("signUp")}
                </Link>
                <button
                  type="button"
                  className="btn btn-save"
                  onClick={async () => {
                    let redirect = `/verify-otp${
                        searchParams.get("redirectTo")
                            ? `?redirectTo=${searchParams.get("redirectTo")}`
                            : ""
                    }${
                        searchParams.get("service")
                            ? `&service=${searchParams.get("service")}`
                            : ""
                    }${
                        searchParams.get("name")
                            ? `&service=${searchParams.get("name")}`
                            : ""
                    }${
                        searchParams.get("lng")
                            ? ((searchParams.get("redirectTo") || searchParams.get("service")) ? `&lng=${searchParams.get("lng")}` : `?lng=${searchParams.get("lng")}`) : ""
                    }`
                    await loginStore.login(redirect)
                  }}
                  disabled={loginStore.isLoading}
                >
                    {t("signIn")}
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
      <div className='login_footer'>
        © 2023 Ommani v.1.3
      </div>
    </div>
  );
});

export default LoginPage;
