/* eslint-disable @typescript-eslint/no-useless-constructor */
import React, {Component, createRef} from "react";
import {observer} from "mobx-react";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import vi from "date-fns/locale/vi";

registerLocale("vi", vi);

interface DatePickerSingleProps {
  selected: any;
  minDate?: any;
  maxDate?: any;
  disabled?: boolean;
  onChange: any;
  placeholder?: any;
  showTimeSelect?: any;
  isError?: any;
  isErrorText?: any;
  dateformat?: any;
  showMonthYearPicker?: boolean;
  locate?: string;
}

@observer
class DatePickerSingle extends Component<DatePickerSingleProps, any> {
  datePickerRef = createRef<any>();

  constructor(props: DatePickerSingleProps) {
    super(props);
  }

  handleChangeTime = (date: Date, event: any) => {
    this.props.onChange(date);
  };
  handleIconClick = () => {
    if (this.datePickerRef.current) {
      this.datePickerRef.current.setFocus(); // Tập trung vào DatePicker để mở lịch
    }
  };

  render() {
    try {
      return (
          <>
            <div
                className={`time_range position-relative`}
            >
              <DatePicker
                  ref={this.datePickerRef}
                  locale={this.props.locate ?? "en"}
                  selected={this.props.selected ? this.props.selected : ""}
                  className={(this.props.isError && !this.props.selected) ? "border_error" : ""}
                  minDate={this.props.minDate}
                  maxDate={this.props.maxDate}
                  showTimeSelect={this.props.showTimeSelect}
                  disabled={this.props.disabled}
                  dateFormat={this.props.dateformat ?? "dd/MM/yyyy"}
                  placeholderText={this.props.placeholder ?? "Chọn ngày"}
                  onChange={this.handleChangeTime}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  showMonthYearPicker={this.props.showMonthYearPicker ?? false}
              />
              <i
                  className="fal fa-calendar"
                  onClick={this.handleIconClick} // Xử lý sự kiện click để mở DatePicker
                  style={{cursor: "pointer"}} // Thêm cursor pointer để biết có thể click
              />
            </div>
          {this.props.isError &&
            !this.props.selected &&
            !this.props.isErrorText && (
              <p className="error">{this.props.isError}</p>
            )}
        </>
      );
    } catch (e) {
      return null;
    }
  }
}

export default DatePickerSingle;
