import i18next from "i18next";
import {initReactI18next} from "react-i18next";
// @ts-ignore
import translationVI from '../locales/vi/translation.json';
import translationEN from '../locales/en/translation.json';
export const uiLanguageOptions = [
    {label: "Việt Nam", value: "vi"},
    {label: "English", value: 'en'},
];
const i18nResource = {
    vi: {
        translation: translationVI,
    },
    en: {
        translation: translationEN,
    },
};
const urlParams = new URLSearchParams(window.location.search);
const langParam = urlParams.get('lng');

i18next.use(initReactI18next).init({
    lng: langParam ?? 'vi', // Ngôn ngữ mặc định
    fallbackLng: 'vi',
    supportedLngs: uiLanguageOptions.map((item) => item.value),
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
    resources: i18nResource,
});
export default i18next;