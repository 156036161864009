import React from 'react';

interface IProps {
    value: any,
    onChange: any
    isError?: any,
    isShowError?: boolean
    options: any[],
    className?: string,
    disabled?: boolean
    placeholder: string
}


const Select = ({value, placeholder, onChange, isError, className, disabled, options, isShowError = true}: IProps) => {


    return (
        <>
            <div className="input-group">
                <select
                    className={`form-control form-control-lg ${className} ${(isError && !value) ? 'border_error' : ''}`}
                    value={value} disabled={disabled} onChange={(e: any) => onChange(e)}>
                    <option value="" disabled>{placeholder}</option>
                    {options && options.map((item, i) => {
                        return <option disabled={item.isDisabled}
                                       key={i}
                                       value={item.id}>{item.name}</option>
                    })}
                </select>
            </div>
            {(isError && !value && isShowError) && <p className="error">{isError}</p>}
        </>
    );
}

export default Select;