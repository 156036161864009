import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { signUpStore } from "./SignUpStore";
import { Link, useSearchParams } from "react-router-dom";
import { loginStore } from "../login/LoginStore";
import DatePickerSingle from "../../../common/component/DatePickerSingle";
import Select from "../../../common/component/Select";
import "./SignUpStyle.css";
import { useTranslation } from "react-i18next";
const SignUpPage = () => {
  const [searchParams] = useSearchParams();

  let url = window.location.href;

  useEffect(() => {
    loginStore.isCms = searchParams.get("cms");
    loginStore.service = searchParams.get("service");
    loginStore.redirectTo = searchParams.get("redirectTo");
  }, [searchParams, url]);

  const handleFilterDate = async (startDate: Date) => {
    signUpStore.dataRequest.birthDay = startDate.getTime();
  };
  const { t } = useTranslation(["translation"], {
    keyPrefix: "signUp",
  });
  return (
    <div className="signUp_main">
      {signUpStore.step === 1 ? (
        <div className="form_signUp">
          <div className="logo">
            <img src="/assets/images/logo.svg" alt="" />
          </div>
          <p>{t("createOmmaniAccount")}</p>

          <div className="form-group">
            <label>
              {t("phoneNumber")} <span className="text-danger">*</span>
            </label>
            <div className="input-group">
              <input
                type="text"
                value={signUpStore.dataRequest.phone ?? ""}
                onChange={(e: any) => signUpStore.dataRequest.phone = e.currentTarget.value.replace(/\D/g, '').slice(0, 10)
                }
                className={`form-control form-control-lg ${
                  signUpStore.errors.phone ? "border_error" : ""
                }`}
                placeholder={t("enterYourPhoneNumber")}
              />
            </div>
            <p className="error">
              {signUpStore.errors && signUpStore.errors.phone}
            </p>
          </div>
          <div className="form-group">
            <label>Email <span className="text-danger">*</span></label>
            <div className="input-group">
              <input
                  type="email"
                  value={signUpStore.dataRequest.email ?? ""}
                  onChange={(e: any) =>
                      (signUpStore.dataRequest.email = e.currentTarget.value)
                  }
                  className={`form-control form-control-lg ${
                      signUpStore.errors.email ? "border_error" : ""
                  }`}
                  placeholder={t("enterEmail")}
              />
            </div>
            <p className="error">
              {signUpStore.errors && signUpStore.errors.email}
            </p>
          </div>
          <div className="d-flex">
            <div className="w-50" style={{ paddingRight: 6 }}>
              <div className="form-group">
                <label>
                  {t("password")} <span className="text-danger">*</span>
                </label>
                <div className="input-group">
                  <input
                    type={signUpStore.showPass ? "text" : "password"}
                    value={signUpStore.dataRequest.password ?? ""}
                    onChange={(e: any) =>
                      (signUpStore.dataRequest.password = e.currentTarget.value)
                    }
                    className={`form-control form-control-lg ${
                      signUpStore.errors.password ? "border_error" : ""
                    }`}
                    placeholder={t("enterYourPassword")}
                  />
                </div>
                <p className="error">
                  {signUpStore.errors && signUpStore.errors.password}
                </p>
              </div>
            </div>
            <div className="w-50" style={{ paddingLeft: 6 }}>
              <div className="form-group">
                <label>
                  {t("passwordConfirm")} <span className="text-danger">*</span>
                </label>
                <div className="input-group">
                  <input
                    type={signUpStore.showPass ? "text" : "password"}
                    value={signUpStore.dataRequest.confirmPass ?? ""}
                    onChange={(e: any) =>
                      (signUpStore.dataRequest.confirmPass =
                        e.currentTarget.value)
                    }
                    className={`form-control form-control-lg ${
                      signUpStore.errors.confirmPass ? "border_error" : ""
                    }`}
                    placeholder={t("rePassword")}
                  />
                </div>
                <p className="error">
                  {signUpStore.errors && signUpStore.errors.confirmPass}
                </p>
              </div>
            </div>
          </div>
          <span
            style={{
              fontSize: "13px",
              fontWeight: "400",
              color: "#5F6368",
              marginBottom: "16px",
            }}
          >
            {t("moreSixWords")}
          </span>
          <div className="my-2 mt-2 d-flex justify-content-start align-items-center">
            <div className="d-flex justify-content-start align-items-center">
              <input
                type="checkbox"
                id="checkbox"
                className={`cursor-pointer 
                        w-4 h-4 border-3 rounded-sm`}
                style={{ marginRight: "6px", cursor: "pointer" }}
                checked={signUpStore.showPass}
                onChange={(e) => {
                  signUpStore.showPass = !signUpStore.showPass;
                }}
              />
              <label
                htmlFor="checkbox"
                style={{ cursor: "pointer" }}
                className="form-check-label mb-0 text-muted"
              >
                {t("showPassword")}
              </label>
            </div>
          </div>

          <div className="footer_action">
            <Link
              style={{ textDecoration: "none" }}
              to={`/login${searchParams.get("redirectTo") ? `?redirectTo=${searchParams.get("redirectTo")}`: ""}${searchParams.get("service") ? searchParams.get("redirectTo") ? `&service=${searchParams.get("service")}` : `?service=${searchParams.get("service")}` : ""}`}
            >
              <div className="btn bt-login">{t("signIn")}</div>
            </Link>
            <button
              type="button"
              className="btn bt-register"
              onClick={() => signUpStore.validateStepOne()}
              disabled={signUpStore.isLoading}
            >
              {signUpStore.isLoading ? (
                <i className="fa fa-spinner fa-spin" />
              ) : (
                `${t("continue")}`
              )}
            </button>
          </div>
        </div>
      ) : (
        <div className="form_signUp">
          <div className="logo">
            <img src="/assets/images/logo.svg" alt="" />
          </div>
          <p>{t("createOmmaniAccount")}</p>
          <div className="d-flex flex-column">

            <label style={{ lineHeight: "30px", fontSize: "16px" }}>
              {t("phoneNumber")} :{" "}
              <span style={{ fontWeight: "600" }}>
                {signUpStore.dataRequest.phone}
              </span>
            </label>
            <label style={{ lineHeight: "30px", fontSize: "16px" }}>
              Email :{" "}
              <span style={{ fontWeight: "600" }}>
                {signUpStore.dataRequest.email}
              </span>
            </label>
          </div>
          <div className="form-group">
            <label>
              {t("fullname")}
              <span className="text-danger">*</span>
            </label>
            <div className="input-group">
              <input
                  type="text"
                  value={signUpStore.dataRequest.name ?? ""}
                  onChange={(e: any) =>
                      (signUpStore.dataRequest.name = e.currentTarget.value)
                  }
                  className={`form-control form-control-lg ${
                      signUpStore.errors.name ? "border_error" : ""
                  }`}
                  placeholder={t("enterYourFullname")}
              />
            </div>
            <p className="error">
              {signUpStore.errors && signUpStore.errors.name}
            </p>
          </div>
          <div className="form-group">
            <label>{t("dateOfBirth")}</label>
            <DatePickerSingle
              locate={searchParams.get("lng") === "en" ? "en" : "vi"}
              selected={signUpStore.dataRequest.birthDay}
              onChange={handleFilterDate}
              placeholder={t("chooseDateOfBirth")}
              dateformat={"dd/MM/yyyy"}
            />
          </div>

          <div className="w-100">
            <div className="form-group">
              <label>{t("gender")}</label>
              <Select
                value={signUpStore.dataRequest.gender}
                isShowError={false}
                className="select-align-left"
                onChange={(e: any) => {
                  signUpStore.dataRequest.gender = e.currentTarget.value;
                }}
                placeholder={t("chooseGender")}
                options={[
                  { id: "MALE", name: `${t("male")}` },
                  { id: "FEMALE", name: `${t("female")}` },
                ]}
              />
              <p className="error">
                {signUpStore.errors && signUpStore.errors.password}
              </p>
            </div>
          </div>
          <div className="my-2 mt-0 d-flex flex-column">
            <div className="d-flex justify-content-start align-items-center">
              <input
                type="checkbox"
                id="checkbox"
                className={`cursor-pointer 
                        w-4 h-4 border-3 rounded-sm`}
                style={{ marginRight: "6px", cursor: "pointer" }}
                checked={signUpStore.acceptTerms && true}
                onChange={(e) => {
                  signUpStore.acceptTerms = !signUpStore.acceptTerms;
                }}
              />
              <label
                htmlFor="checkbox"
                style={{ cursor: "pointer" }}
                className="form-check-label mb-0 text-muted"
              >
                {t("agreeTerms")}
              </label>
            </div>
            {signUpStore?.errors.acceptTerms && (
              <p className="error">{signUpStore.errors.acceptTerms}</p>
            )}
          </div>

          <div className="footer_action">
            <button
              type="button"
              className="btn bt-login"
              onClick={() => (signUpStore.step = 1)}
            >
              {t("back")}
            </button>
            <button
              type="button"
              className="btn bt-register"
              onClick={async () => {
                // let redirectUrl = `/confirm-otp${
                //     searchParams.get("redirectTo")
                //         ? `?redirectTo=${searchParams.get("redirectTo")}`
                //         : ""
                // }${
                //     searchParams.get("service")
                //         ? `&service=${searchParams.get("service")}`
                //         : ""
                // }${
                //     searchParams.get("name")
                //         ? `&service=${searchParams.get("name")}`
                //         : ""
                // }${
                //     searchParams.get("lng")
                //         ? `?lng=${searchParams.get("lng")}`
                //         : ""
                // }`
                let redirectUrl = `/login?${signUpStore.dataRequest.phone
                    ? `username=${signUpStore.dataRequest.phone}`
                    : ""}${
                    searchParams.get("redirectTo")
                        ? `&redirectTo=${searchParams.get("redirectTo")}`
                        : ""
                }${
                    searchParams.get("service")
                        ? `&service=${searchParams.get("service")}`
                        : ""
                }${
                    searchParams.get("name")
                        ? `&service=${searchParams.get("name")}`
                        : ""
                }${
                    searchParams.get("lng")
                        ? `&lng=${searchParams.get("lng")}`
                        : ""
                }&form=true`
                signUpStore.serviceSignup = searchParams.get("service") === 'farm' ? 'OMMANI_OTP' : searchParams.get("service") === 'food' ? 'OMFOOD_OTP' :'OMMANI_OTP'
                await signUpStore.signUp(redirectUrl);
                localStorage.setItem('account_verify', signUpStore.dataRequest.username)
              }}
              disabled={signUpStore.isLoading}
            >
              {signUpStore.isLoading ? (
                <i className="fa fa-spinner fa-spin" />
              ) : (
                `${t("signUp")}`
              )}
            </button>
          </div>
        </div>
      )}
      {signUpStore.step === 1 ? (
        <div className="description_signUp">
          <div>
            <img src="/assets/images/mdi_security-account (1).svg" alt="" />
          </div>
          <div className="d-flex mb-1">
            <div className="px-2 py-1">
              <img className='description_signUp--img' src="/assets/images/Logo%20OMF.svg" alt="" />
            </div>
            <div className="px-2 py-1">
              {searchParams.get("name") === "VietFarm" &&
              searchParams.get("service") === "farm" ? (
                <img className='description_signUp--img' src="/assets/images/LOGO%20VF.svg" alt="" />
              ) : (
                <img className='description_signUp--img' src="/assets/images/Logo%20OMFARM.svg" alt="" />
              )}
            </div>
            <div className="px-2 py-1">
              <img className='description_signUp--img'  src="/assets/images/Logo_OMCHECK.svg" alt="" />
            </div>
          </div>
          <div className='d-flex justify-center mb-3'>
            <div className="px-2 py-1">
              <img className='description_signUp--img'  src="/assets/images/Logo_FNB.svg" alt="" />
            </div>
            <div className="px-2 py-1">
              <img className='description_signUp--img'  src="/assets/images/Logo_ISS.svg" alt="" />
            </div>
            <div className="px-2 py-1">
              <img className='description_signUp--img'  src="/assets/images/Logo_CRM.svg" alt="" />
            </div>
            <div className="px-2 py-1">
              <img width={60} className='description_signUp--img'  src="/assets/images/logo-xweb.svg" alt="" />
            </div>
          </div>
          <div style={{ width: "70%" }} className="text-center title-signUp">
            {t("slogan")}
          </div>
          <div className='login_footer'>
            © 2023 Ommani v.1.3
          </div>
        </div>
      ) : (
        <div className="description_signUp">
          <div>
            <img src="/assets/images/mdi_security-account.svg" alt="" />
          </div>
          <div style={{ width: "70%" }} className="text-center title-signUp">
            {t("infoSlogan")}
          </div>
          <div className='login_footer'>
            © 2023 Ommani v.1.3
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(SignUpPage);
