import {Route, Routes} from "react-router-dom";
import React from "react";
import LoginPage from "../auth/login/LoginPage";
import SignUpPage from "../auth/signUp/SignUpPage";
import InforAccountPage from "../profile/infor/inforAccount/InforAccountPage";
import ForgotPasswordPage from "../auth/forgotPassword/ForgotPassword";
import ConfirmOTP from "../auth/forgotPassword/ConfirmOTP";
import ResetPasswordPage from "../auth/forgotPassword/ResetPassword";

export default function Redirect() {
    return (
        <Routes>
            <Route path={`/`} element={<LoginPage/>}/>
            <Route path={`/login`} element={<LoginPage/>}/>
            <Route path={`/register`} element={<SignUpPage/>}/>
            <Route path={`/verify-otp`} element={<ForgotPasswordPage/>}/>
            <Route path={`/confirm-otp`} element={<ConfirmOTP/>}/>
            <Route path={`/reset-password`} element={<ResetPasswordPage/>}/>
            <Route path={`/profile`} element={<InforAccountPage/>}/>
        </Routes>
    )
}