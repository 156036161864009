import React from "react";
import { observer } from "mobx-react";
import { inforStore } from "../InforAccountStore";
import "../InforAccount.scss";
import { useTranslation } from "react-i18next";

const ChangePass = (): JSX.Element => {
  const { t } = useTranslation(["translation"], {
    keyPrefix: "profile",
  });

  return (
    <div className="form-change-pass">
      <h1 className="form-change-pass-title">{t("changePass")}</h1>

      <div className="col-10" style={{ paddingRight: 6 }}>
        <div className="form-group">
          <label>
            {t("passwordCurr")}
            <span className="text-danger"> *</span>
          </label>
          <div className="input-group">
            <input
              type={inforStore.showPass ? "text" : "password"}
              value={inforStore.userPassword.oldPassword ?? ""}
              onChange={(e: any) =>
                (inforStore.userPassword.oldPassword = e.currentTarget.value)
              }
              className={`form-control form-control-lg ${
                inforStore.errors.oldPassword ? "border_error" : ""
              }`}
              placeholder={t("enterYourPasswordCurr")}
            />
          </div>
          <p className="error">
            {inforStore.errors && inforStore.errors.oldPassword}
          </p>
        </div>
      </div>
      <div className="col-10" style={{ paddingRight: 6 }}>
        <div className="form-group">
          <label>
            {t("passwordNew")}
            <span className="text-danger"> *</span>
          </label>
          <div className="input-group">
            <input
              type={inforStore.showPass ? "text" : "password"}
              value={inforStore.userPassword.password ?? ""}
              onChange={(e: any) =>
                (inforStore.userPassword.password = e.currentTarget.value)
              }
              className={`form-control form-control-lg ${
                inforStore.errors.password ? "border_error" : ""
              }`}
              placeholder={t("enterYourPasswordNew")}
            />
          </div>
          <p className="error">
            {inforStore.errors && inforStore.errors.password}
          </p>
        </div>
      </div>
      <div className="col-10" style={{ paddingRight: 6 }}>
        <div className="form-group">
          <label>
            {t("passwordConfirm")} <span className="text-danger"> *</span>
          </label>
          <div className="input-group">
            <input
              type={inforStore.showPass ? "text" : "password"}
              value={inforStore.userPassword.confirmPass ?? ""}
              onChange={(e: any) =>
                (inforStore.userPassword.confirmPass = e.currentTarget.value)
              }
              className={`form-control form-control-lg ${
                inforStore.errors.confirmPassword ? "border_error" : ""
              }`}
              placeholder={t("rePassword")}
            />
          </div>
          <p className="error">
            {inforStore.errors && inforStore.errors.confirmPassword}
          </p>
        </div>
      </div>
      <div className="d-flex flex-column align-items-start col-10">
        <span
          style={{
            fontSize: "13px",
            fontWeight: "400",
            color: "#5F6368",
            marginBottom: "12px",
          }}
        >
          {t("moreSixWords")}
        </span>
        <div className="my-2 mt-0 d-flex justify-content-between align-items-center">
          <div className="d-flex justify-content-start align-items-center">
            <input
              type="checkbox"
              id="checkbox"
              className={`cursor-pointer 
                        w-4 h-4 border-3 rounded-sm`}
              style={{ marginRight: "6px", cursor: "pointer" }}
              checked={inforStore.showPass && true}
              onChange={() => {
                inforStore.showPass = !inforStore.showPass;
              }}
            />
            <label
              htmlFor="checkbox"
              style={{ cursor: "pointer" }}
              className="form-check-label mb-0 text-muted"
            >
              {t("showPassword")}
            </label>
          </div>
        </div>
      </div>

      <div style={{ textAlign: "right", width: "75%" }}>
        <button
          type="button"
          className="btn btn-save"
          onClick={async () => await inforStore.changePassword()}
          disabled={inforStore.isLoadingBt}
        >
          {inforStore.isLoadingBt ? (
            <i className="fa fa-spinner fa-spin" />
          ) : (
            `Lưu`
          )}
        </button>
      </div>
        <div className='login_footer_fixed'>
            © 2023 Ommani v.1.3
        </div>
    </div>
  );
};

export default observer(ChangePass);
