import React, {useEffect} from "react";
import { observer } from "mobx-react";
import {Link, useSearchParams} from "react-router-dom";
// @ts-ignore
import {loginStore} from "../login/LoginStore";
const ResetPasswordPage = observer(() => {
    let searchParams: URLSearchParams;
    [searchParams] = useSearchParams();
    let url = window.location.href;
    useEffect(() => {
        if (localStorage.getItem("listAccount")) {
            loginStore.showForm = false;
            let listAcc: any = localStorage.getItem("listAccount");
            loginStore.listAccount = JSON.parse(listAcc);
        }
        if (searchParams.get("form")) {
            loginStore.showForm = true;
            loginStore.username =
                searchParams.get("username") === "null"
                    ? ""
                    : (searchParams.get("username") as any);
        }
        loginStore.isCms = searchParams.get("cms");
        loginStore.service = searchParams.get("service");
        loginStore.redirectTo = searchParams.get("redirectTo");
    }, [searchParams, url]);
    return (
        <div className="login_main">
            {loginStore.listAccount &&
                loginStore.listAccount.length > 0 && (
                    <div className="button_back_list_account_container">
                        <Link
                            to={'/login'}
                            onClick={() => (loginStore.showForm = false)}
                            className="button_back_list_account"
                        >
                            <img
                                style={{ marginRight: "6px" }}
                                src="/assets/images/arrow-big-left.svg"
                                alt=""
                            />
                            <span style={{ color: "#000" }}>Danh sách tài khoản</span>
                        </Link>
                    </div>
                )}
            <div className="login_main_container">
                <div className="form_login bg-white">
                    <div className="logo_login d-flex justify-content-center">
                        <img src="/assets/images/logo.svg" alt="" />
                    </div>
                    <h2 className="text-center mb-3">
                        Quên mật khẩu
                    </h2>
                    {loginStore.typeShowConfirmOtp !== 'change_success' ? <form className={'changePass_otp__container'} autoComplete="off">
                        <h1 className='text-center'>TẠO MẬT KHẨU</h1>
                        <div className="form-group mt-5">
                            <div className="form-group">
                                <label>
                                    Mật khẩu mới <span className="text-danger">*</span>
                                </label>
                                <div className="input-group">
                                    <input
                                        type={loginStore.showPass ? "text" : "password"}
                                        value={loginStore.resetPassword?? ""}
                                        onChange={(e: any) =>
                                            (loginStore.resetPassword = e.currentTarget.value)
                                        }
                                        className={`form-control form-control-lg ${
                                            loginStore.formResetPasswordError.resetPassword ? "border_error" : ""
                                        }`}
                                        placeholder="Nhập mật khẩu mới"
                                    />
                                </div>
                                <p className="error">
                                    {loginStore.formResetPasswordError && loginStore.formResetPasswordError.resetPassword}
                                </p>
                            </div>
                            <div className="form-group">
                                <label>
                                    Nhập lại mật khẩu mới <span className="text-danger">*</span>
                                </label>
                                <div className="input-group">
                                    <input
                                        type={loginStore.showPass ? "text" : "password"}
                                        value={loginStore.resetPasswordCofirm ?? ""}
                                        onChange={(e: any) =>
                                            (loginStore.resetPasswordCofirm =
                                                e.currentTarget.value)
                                        }
                                        className={`form-control form-control-lg ${
                                            loginStore.formResetPasswordError.resetPasswordCofirm ? "border_error" : ""
                                        }`}
                                        placeholder="Nhập lại mật khẩu mới"
                                    />
                                </div>
                                {loginStore.formResetPasswordError?.resetPasswordCofirm ? <p className="error">
                                    {loginStore.formResetPasswordError?.resetPasswordCofirm}
                                </p> : <label className='form-check-label mb-0 text-muted'>
                                    Sử dụng 6 ký tự trở lên và kết hợp chữ cái, chữ số và biểu tượng.
                                </label>
                                }
                            </div>
                            <div className="d-flex justify-content-start align-items-center">
                                <input
                                    type="checkbox"
                                    id="checkbox"
                                    className={`cursor-pointer 
                        w-4 h-4 border-3 rounded-sm`}
                                    style={{ marginRight: "6px", cursor: "pointer" }}
                                    checked={loginStore.showPass}
                                    onChange={(e) => {
                                        loginStore.showPass = !loginStore.showPass;
                                    }}
                                />
                                <label
                                    htmlFor="checkbox"
                                    style={{ cursor: "pointer" }}
                                    className="form-check-label mb-0 text-muted"
                                >
                                    Hiển thị mật khẩu
                                </label>
                            </div>
                        </div>
                        <div style={{marginTop: '20px'}} className="footer_action">
                            <Link
                                to={`/confirm-otp${
                                    searchParams.get("redirectTo")
                                        ? `?redirectTo=${searchParams.get("redirectTo")}`
                                        : ""
                                }${
                                    searchParams.get("service")
                                        ? `&service=${searchParams.get("service")}`
                                        : ""
                                }${
                                    searchParams.get("name")
                                        ? `&service=${searchParams.get("name")}`
                                        : ""
                                }${
                                    searchParams.get("lng")
                                        ? `?lng=${searchParams.get("lng")}`
                                        : ""
                                }`}
                                className="btn bt-register"
                            >
                                Quay lại
                            </Link>
                            <button
                                type="button"
                                className="btn btn-save"
                                onClick={async () => {
                                    await loginStore.verifyOTPForgotPassword()
                                }}
                                disabled={loginStore.isLoading}
                            >
                                {loginStore.isLoading ? (
                                    <i className="fa fa-spinner fa-spin" />
                                ) : (
                                    'Gửi'
                                )}
                            </button>
                        </div>
                    </form> : <form autoComplete="off">
                        <div className='d-flex flex-column forgot_password_success'>
                            <img src="/assets/images/forgot_password_success.svg" alt="" className='my-3'/>
                            <h2>Đổi mật khẩu thành công!</h2>
                            <span>Việc thay đổi mật khẩu của bạn sẽ khiến bạn đăng xuất khỏi tất cả các thiết bị. Hãy nhập lại mật khẩu mới.</span>
                        </div>
                        <div className="footer_action">
                            <Link to={`/login${
                            searchParams.get("redirectTo")
                                ? `?redirectTo=${searchParams.get("redirectTo")}`
                                : ""
                        }${
                            searchParams.get("service")
                                ? `&service=${searchParams.get("service")}`
                                : ""
                        }${
                            searchParams.get("name")
                                ? `&service=${searchParams.get("name")}`
                                : ""
                        }${
                            searchParams.get("lng")
                                ? `?lng=${searchParams.get("lng")}`
                                : ""
                        }${
                                (!searchParams.get("redirectTo") &&  !searchParams.get("service") && !searchParams.get("name") && !searchParams.get("lng") )
                                    ? `?form=true`
                                    : `&form=true`
                            }`} className={'w-100'}>
                                <button
                                    type="button"
                                    className="btn btn-verify-otp"
                                    onClick={() => loginStore.typeShowConfirmOtp = "changePassword"}
                                    disabled={loginStore.isLoading}
                                >
                                    {loginStore.isLoading ? (
                                        <i className="fa fa-spinner fa-spin" />
                                    ) : (
                                        'Đăng nhập'
                                    )}
                                </button>
                            </Link>
                        </div>
                    </form>}
                </div>
            </div>
        </div>
    );
});

export default ResetPasswordPage;
