import React, {useEffect} from "react";
import { observer } from "mobx-react";
import {loginStore} from "../login/LoginStore";
import {Link, useSearchParams} from "react-router-dom";
// @ts-ignore
import OTPInput, { ResendOTP } from "otp-input-react";
import {validatePhoneNumber} from "../../../common/utils/Utils";
const ForgotPasswordPage = observer(() => {

    let searchParams: URLSearchParams;
    [searchParams] = useSearchParams();
    let url = window.location.href;
    useEffect(() => {
        if (localStorage.getItem("listAccount")) {
            loginStore.showForm = false;
            let listAcc: any = localStorage.getItem("listAccount");
            loginStore.listAccount = JSON.parse(listAcc);
        }
        if (searchParams.get("form")) {
            loginStore.showForm = true;
            loginStore.username =
                searchParams.get("username") === "null"
                    ? ""
                    : (searchParams.get("username") as any);
        }
        loginStore.isCms = searchParams.get("cms");
        loginStore.service = searchParams.get("service");
        loginStore.redirectTo = searchParams.get("redirectTo");
    }, [searchParams, url]);
    let redirect = `/confirm-otp${
        searchParams.get("redirectTo")
            ? `?redirectTo=${searchParams.get("redirectTo")}`
            : ""
    }${
        searchParams.get("service")
            ? `&service=${searchParams.get("service")}`
            : ""
    }${
        searchParams.get("name")
            ? `&service=${searchParams.get("name")}`
            : ""
    }${
        searchParams.get("lng")
            ? `?lng=${searchParams.get("lng")}`
            : ""
    }`
    const handleSendOtp = () => {
        if (!validatePhoneNumber(loginStore.account_verify)) {
            loginStore.account_verify = ''
            loginStore.formForgotPasswordError.name = "Vui lòng nhập số điện thoại đúng định dạng!"
            return
        } else {
            loginStore.formForgotPasswordError.name = ''
            localStorage.setItem('account_verify', loginStore.account_verify)
            window.location.href = redirect
        }
    }
    const enterSendOtp = async (e: any) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            await handleSendOtp()
        }
    }
    return (
        <div className="login_main">
            {loginStore.listAccount &&
                loginStore.listAccount.length > 0 && (
                    <div className="button_back_list_account_container">
                        <Link
                            to={ `/login${
                                searchParams.get("redirectTo")
                                    ? `?redirectTo=${searchParams.get("redirectTo")}`
                                    : ""
                            }${
                                searchParams.get("service")
                                    ? `&service=${searchParams.get("service")}`
                                    : ""
                            }${
                                searchParams.get("name")
                                    ? `&service=${searchParams.get("name")}`
                                    : ""
                            }${
                                searchParams.get("lng")
                                    ? `&lng=${searchParams.get("lng")}`
                                    : ""
                            }${
                                !(localStorage.getItem('type_verify') === 'LOGIN') ? '' : (!searchParams.get("redirectTo") &&  !searchParams.get("service") && !searchParams.get("name") && !searchParams.get("lng"))
                                    ? `?form=true`
                                    : `&form=true`
                            }`}
                            onClick={() => (loginStore.showForm = false)}
                            className="button_back_list_account"
                        >
                            <img
                                style={{ marginRight: "6px" }}
                                src="/assets/images/arrow-big-left.svg"
                                alt=""
                            />
                            <span style={{ color: "#000" }}>Danh sách tài khoản</span>
                        </Link>
                    </div>
                )}
            <div className="login_main_container">
                <div className="form_login bg-white">
                    <div className="logo_login d-flex justify-content-center">
                        <img src="/assets/images/logo.svg" alt="" />
                    </div>
                    <h2 className="text-center mb-3">
                        {localStorage.getItem('type_verify') === 'LOGIN' ? 'Xác thực tài khoản' : 'Quên mật khẩu'}
                    </h2>
                    <form autoComplete="off">
                        <div className="form-group mt-5">
                            <label>
                                Email hoặc Số điện thoại đăng ký <span className="text-danger">*</span>
                            </label>
                            <div className="input-group">
                                <input
                                    type="text"
                                    value={loginStore.account_verify}
                                    onChange={(e: any) => {
                                        loginStore.account_verify = e.target.value;
                                    }}
                                    onKeyDown={enterSendOtp}
                                    className={`form-control form-control-lg ${
                                        !loginStore.account_verify && loginStore.formForgotPasswordError?.name ? "border_error" : ""
                                    }`}
                                    placeholder="Nhập email hoặc số điện thoại"
                                />
                            </div>
                            {loginStore.formForgotPasswordError?.name && !loginStore.account_verify ? <p className="error">
                                {loginStore.formForgotPasswordError?.name}
                            </p> : <label className='form-check-label mb-0 text-muted'>
                                Mã OTP sẽ được gửi về số điện thoại đã được đăng ký
                            </label>
                            }
                        </div>
                        <div style={{marginTop: '170px'}} className="footer_action">
                            <Link
                                to={`/login${
                                    searchParams.get("redirectTo")
                                        ? `?redirectTo=${searchParams.get("redirectTo")}`
                                        : ""
                                }${
                                    searchParams.get("service")
                                        ? `&service=${searchParams.get("service")}`
                                        : ""
                                }${
                                    searchParams.get("name")
                                        ? `&service=${searchParams.get("name")}`
                                        : ""
                                }${
                                    searchParams.get("lng")
                                        ? `?lng=${searchParams.get("lng")}`
                                        : ""
                                }`}
                                className="btn bt-register"
                            >
                                Quay lại
                            </Link>
                                <button
                                    type="button"
                                    className={`btn btn-save`}
                                    onClick={async () => {
                                    await handleSendOtp()}
                                    }
                                    disabled={loginStore.isLoading}
                                >
                                    {loginStore.isLoadingBt ? (
                                        <i className="fa fa-spinner fa-spin" />
                                    ) : (
                                        'Gửi'
                                    )}
                                </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
});

export default ForgotPasswordPage;
