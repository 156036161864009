import React, {Component} from 'react';

interface IProps {
    isError?: any,
    isShow?: any,
    value?: any,
    onChange: any,
    onKeyDown?: any,
    placeholder?: any
}


class InputPassword extends Component<IProps, any> {

    render() {
        let item = this.props
        return (
            <>
                <div className="input-group input-password position-relative">
                    <input type={!item.isShow ? 'password' : 'text'} autoComplete="new-password" value={item.value} onChange={(e: any) => item.onChange(e)} onKeyDown={(e: any) => item.onKeyDown(e)} className={`form-control form-control-lg ${item.isError ? 'border_error' : ''}`} placeholder={item.placeholder ?? "Nhập mật khẩu"}/>
                </div>
                {item.isError && <p className="error">{item.isError}</p>}
            </>
        );
    }
}

export default InputPassword;