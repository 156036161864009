import React from "react";
import { observer } from "mobx-react";
import { loginStore } from "./LoginStore";
import { profileStore } from "../../profile/profileStore";
import { urlImage } from "../../../common/utils/Utils";
import { randomColor } from "../../profile/infor/inforAccount/components/SelectAccountInfor";
import { useTranslation } from "react-i18next";
import {useSearchParams} from "react-router-dom";

const SelectAccount = () => {
  const { t } = useTranslation(["translation"], {
    keyPrefix: "login",
  });
  const isTokenExpired = (exp: any) => {
    const currentDate = new Date();
    return exp > Math.floor(currentDate.getTime() / 1000);
  };
    let searchParams: URLSearchParams;
    [searchParams] = useSearchParams();
  return (
    <div className="select_account">
      <div className="list_account">
        {Array.from(new Map(loginStore.listAccount.map(account => [account.userId, account])).values())?.map((item, i) => {
          return (
            <div className="account" key={i}>
              <div
                className="d-flex align-items-center w-100 h-100"
                onClick={() => profileStore.getToken(item, searchParams.get("redirectTo"), searchParams.get("service"))}
              >
                {item.avatar ? (
                  <div>
                    <img
                      src={urlImage(item?.avatar) as unknown as string}
                      alt=""
                      className="avatar avatar-image"
                    />
                  </div>
                ) : (
                    item?.name ?
                  <div
                    style={{
                      backgroundColor: randomColor(),
                    }}
                    className="avatar"
                  >
                    <span className="text-white">{item?.name?.slice(0, 1)}</span>
                  </div> : <img style={{border: '1px solid #ccc'}}  className="avatar avatar-image" width={24} src="/assets/images/AvatarTemp.svg" alt=""/>
                )}
                <div className="info">
                  <p>
                    {item?.name}
                    <span
                      style={{
                        color: "#ff7675",
                        fontStyle: "italic",
                        marginLeft: "8px",
                      }}
                    >
                      {!isTokenExpired(item.exp)
                        ? `${t('loginSessionExpired')}`
                        : ""}
                    </span>
                  </p>
                    <span>{item?.email} {(item?.email && item?.phone) ? ' - ': ''} {item?.phone}</span>
                </div>
              </div>
              <img
                className="ico_close"
                title="Đăng xuất"
                onClick={() => {
                    loginStore.listAccount?.forEach((account: any) => {
                        if (account.userId === item.userId)
                        loginStore.logout(account).then()
                    })
                }}
                src="/assets/images/ico_close.svg"
                alt=""
              />
            </div>
          );
        })}
      </div>
      <div
        className="account border-0"
        onClick={() => (loginStore.showForm = true)}
        style={{ cursor: "pointer" }}
      >
        <div className="ico">
          <img src="/assets/images/ico_user.png" alt="" />
        </div>
        <div className="info">
          <p className="m-0">{t("useAnothorAccount")}</p>
        </div>
      </div>
    </div>
  );
};

export default observer(SelectAccount);
