import React, {useEffect, useRef, useState} from "react";
import {observer} from "mobx-react";
import {loginStore} from "../login/LoginStore";
import {Link, useSearchParams} from "react-router-dom";
// @ts-ignore
import OTPInput, {ResendOTP} from "otp-input-react";
import {signUpStore} from "../signUp/SignUpStore";

const ConfirmOTP = observer(() => {
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    let searchParams: URLSearchParams;
    [searchParams] = useSearchParams();
    let url = window.location.href;
    let dataAccountVerify  = String(localStorage.getItem('account_verify')).replace(/(\d{5})\d+(\d{2})/, "$1***$2");
    useEffect(() => {
        if (localStorage.getItem("listAccount")) {
            loginStore.showForm = false;
            let listAcc: any = localStorage.getItem("listAccount");
            loginStore.listAccount = JSON.parse(listAcc);
        }
        signUpStore.serviceSignup = localStorage.getItem('type_verify') === 'LOGIN' ? (searchParams.get("service") === 'farm' ? 'OMMANI_OTP' : searchParams.get("service") === 'food' ? 'OMFOOD_OTP' : 'OMMANI_OTP')
            : (searchParams.get("service") === 'farm' ? 'OMMANI_RESET_PASSWORD' : searchParams.get("service") === 'food' ? 'OMFOOD_RESET_PASSWORD' : 'OMMANI_RESET_PASSWORD ')

        if(localStorage.getItem('account_verify')) {
            loginStore.sendOTP(signUpStore.serviceSignup).then(() => {
                const timestamp = loginStore.resendAfter;
                const minutes = Math.floor(timestamp / 60);
                const seconds = (timestamp % 60);
                setMinutes(minutes);
                setSeconds(seconds);
            })
        }
        if (searchParams.get("form")) {
            loginStore.showForm = true;
            loginStore.username =
                searchParams.get("username") === "null"
                    ? ""
                    : (searchParams.get("username") as any);
        }
        loginStore.isCms = searchParams.get("cms");
        loginStore.service = searchParams.get("service");
        loginStore.redirectTo = searchParams.get("redirectTo");
    }, [searchParams, url]);
    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setSeconds(59);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    });

    const handleVerifyOTP = async (e: any) => {
        e.preventDefault()
        let redirectUrl = `/${localStorage.getItem('type_verify') === 'LOGIN' ? 'login' : 'reset-password'}${
            searchParams.get("redirectTo")
                ? `?redirectTo=${searchParams.get("redirectTo")}`
                : ""
        }${
            searchParams.get("service")
                ? `&service=${searchParams.get("service")}`
                : ""
        }${
            searchParams.get("name")
                ? `&service=${searchParams.get("name")}`
                : ""
        }${
            searchParams.get("lng")
                ? `&lng=${searchParams.get("lng")}`
                : ""
        }${
            !(localStorage.getItem('type_verify') === 'LOGIN') ? '' : (!searchParams.get("redirectTo") &&  !searchParams.get("service") && !searchParams.get("name") && !searchParams.get("lng"))
                ? `?form=true`
                : `&form=true`
        }`
        localStorage.setItem('otp_forgot', loginStore.otpCode)
        await loginStore.verifyOTP(redirectUrl)
    }

    return (
        <div className="login_main">
            {loginStore.listAccount &&
                loginStore.listAccount.length > 0 && (
                    <div className="button_back_list_account_container">
                        <Link
                            to={ `/login${
                                searchParams.get("redirectTo")
                                    ? `?redirectTo=${searchParams.get("redirectTo")}`
                                    : ""
                            }${
                                searchParams.get("service")
                                    ? `&service=${searchParams.get("service")}`
                                    : ""
                            }${
                                searchParams.get("name")
                                    ? `&service=${searchParams.get("name")}`
                                    : ""
                            }${
                                searchParams.get("lng")
                                    ? `&lng=${searchParams.get("lng")}`
                                    : ""
                            }${
                                !(localStorage.getItem('type_verify') === 'LOGIN') ? '' : (!searchParams.get("redirectTo") &&  !searchParams.get("service") && !searchParams.get("name") && !searchParams.get("lng"))
                                    ? `?form=true`
                                    : `&form=true`
                            }`}
                            onClick={() => (loginStore.showForm = false)}
                            className="button_back_list_account"
                        >
                            <img
                                style={{ marginRight: "6px" }}
                                src="/assets/images/arrow-big-left.svg"
                                alt=""
                            />
                            <span style={{ color: "#000" }}>Danh sách tài khoản</span>
                        </Link>
                    </div>
                )}
            <div className="login_main_container">
                <div className="form_login bg-white">
                    <div className="logo_login d-flex justify-content-center">
                        <img src="/assets/images/logo.svg" alt="" />
                    </div>
                    <form onSubmit={handleVerifyOTP} className='confirm_otp__container mt-3 text-center'>
                        <h1>XÁC THỰC MÃ OTP</h1>
                        {localStorage.getItem('account_verify') && <span>Mã xác thực đã được gửi đến số điện thoại {dataAccountVerify}</span>}
                        <div className='d-flex w-100 justify-content-center mt-5'>
                            <OTPInput
                                className={'otp_container'}
                                value={loginStore.otpCode}
                                onChange={(e: any) => loginStore.otpCode = e}
                                autoFocus OTPLength={6}
                                otpType="number" />
                        </div>
                        <div className='d-flex flex-column w-100 justify-content-center align-items-center mt-3 otp_container__time-container'>
                            <div className="countdown-text mb-2">
                                {(
                                    <p>
                                        {minutes < 10 ? `0${minutes}` : minutes}:
                                        {seconds < 10 ? `0${seconds}` : seconds}
                                    </p>
                                )}
                            </div>
                            <button type='button' disabled={(minutes !== 0 || seconds !== 0)} onClick={async () => {
                                await loginStore.sendOTP(signUpStore.serviceSignup).then()
                            }} className={`countdown-button ${(minutes !== 0 || seconds !== 0) ? 'countdown-button__disable' : ''}`}>Gửi lại mã</button>
                        </div>
                        <button
                            type="submit"
                            className="btn btn-verify-otp"
                            onClick={handleVerifyOTP}
                            disabled={loginStore.otpCode.length !== 6 || loginStore.isLoadingBt}
                        >
                            {loginStore.isLoadingBt ? (
                                <i className="fa fa-spinner fa-spin" />
                            ) : (
                                'Xác thực'
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
});

export default ConfirmOTP;
